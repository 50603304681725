import React from 'react';

export const AppStore = (props) => {
  return (
    <svg width="176" height="52" viewBox="0 0 176 52" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M161.524 0.000168999H13.9836C13.4458 0.000168999 12.9145 0.000169 12.3781 0.00276899C11.9291 0.00536898 11.4837 0.012922 11.0303 0.0192789C10.0455 0.0295512 9.06305 0.106348 8.09141 0.249001C7.12114 0.39476 6.18126 0.669557 5.30357 1.0641C4.42695 1.46198 3.62596 1.97898 2.92965 2.59635C2.22968 3.21199 1.64621 3.92358 1.20166 4.70376C0.755889 5.48239 0.446787 6.31677 0.285035 7.17807C0.121744 8.03828 0.0338762 8.90842 0.0222191 9.78066C0.00860898 10.1792 0.0071717 10.5791 0 10.9778V41.0262C0.0071717 41.4299 0.00860898 41.8209 0.0222191 42.2247C0.0338798 43.0969 0.121748 43.967 0.285035 44.8271C0.446341 45.6889 0.755461 46.5238 1.20166 47.3027C1.64601 48.0804 2.22956 48.7891 2.92965 49.4013C3.62332 50.0214 4.42483 50.5388 5.30357 50.9336C6.18126 51.3292 7.12103 51.6056 8.09141 51.7537C9.06321 51.8952 10.0456 51.972 11.0303 51.9835C11.4837 51.9923 11.9291 51.9974 12.3781 51.9974C12.9144 52 13.4458 52 13.9836 52H161.524C162.051 52 162.587 52 163.114 51.9974C163.561 51.9974 164.019 51.9923 164.466 51.9835C165.449 51.9726 166.43 51.8958 167.399 51.7537C168.373 51.6046 169.316 51.3282 170.198 50.9336C171.076 50.5385 171.877 50.0212 172.57 49.4013C173.268 48.7867 173.853 48.0785 174.303 47.3027C174.746 46.5232 175.052 45.6884 175.211 44.8271C175.374 43.9669 175.465 43.097 175.483 42.2247C175.489 41.8209 175.489 41.4299 175.489 41.0262C175.5 40.5539 175.5 40.0842 175.5 39.6043V12.3971C175.5 11.921 175.5 11.4488 175.489 10.9778C175.489 10.5791 175.489 10.1792 175.483 9.78061C175.465 8.90829 175.374 8.03835 175.211 7.17802C175.051 6.31721 174.745 5.48291 174.303 4.70371C173.397 3.13991 171.962 1.867 170.198 1.06398C169.316 0.670404 168.373 0.39568 167.399 0.248884C166.43 0.105602 165.449 0.028779 164.466 0.0190969C164.019 0.012753 163.561 0.00513498 163.114 0.00259999C162.587 0 162.051 0 161.524 0V0.000168999Z"
        fill="white"
      />
      <path
        d="M11.923 50.9974C11.4734 50.9974 11.0347 50.9923 10.5886 50.9834C9.66457 50.9727 8.74268 50.9015 7.83051 50.7702C6.97996 50.6404 6.156 50.3991 5.38582 50.054C4.6227 49.7119 3.92668 49.2632 3.32441 48.7252C2.71342 48.1936 2.20475 47.577 1.81854 46.8998C1.42808 46.2183 1.15786 45.4877 1.01729 44.7337C0.865487 43.9234 0.783355 43.1041 0.771603 42.2828C0.762248 42.0071 0.75 41.0893 0.75 41.0893V10.894C0.75 10.894 0.763044 9.99029 0.771677 9.72477C0.782929 8.90479 0.864584 8.08676 1.01593 7.27775C1.15676 6.52161 1.42719 5.78896 1.81786 5.10518C2.20265 4.42851 2.7085 3.81144 3.3158 3.27789C3.92243 2.73928 4.62068 2.2883 5.3851 1.9414C6.15351 1.59748 6.97591 1.3578 7.82474 1.23041C8.7399 1.09782 9.66499 1.02614 10.5922 1.01596L11.9237 1H163.569L164.916 1.0166C165.835 1.02627 166.752 1.09732 167.659 1.22913C168.516 1.35812 169.347 1.59947 170.124 1.94523C171.656 2.64434 172.902 3.7504 173.688 5.10837C174.072 5.78743 174.339 6.5141 174.478 7.26371C174.631 8.07936 174.716 8.90382 174.734 9.73053C174.738 10.1007 174.738 10.4983 174.738 10.894C174.75 11.3842 174.75 11.8508 174.75 12.3211V39.6775C174.75 40.1523 174.75 40.6157 174.738 41.0829C174.738 41.5079 174.738 41.8973 174.733 42.2981C174.715 43.1101 174.631 43.9198 174.48 44.7209C174.343 45.4804 174.074 46.2166 173.684 46.9037C173.295 47.5735 172.789 48.1848 172.185 48.715C171.582 49.2559 170.885 49.7072 170.12 50.0516C169.345 50.3992 168.515 50.6415 167.659 50.7702C166.747 50.9022 165.825 50.9734 164.901 50.9834C164.468 50.9923 164.016 50.9974 163.576 50.9974L161.977 51L11.923 50.9974Z"
        fill="black"
      />
      <path
        d="M32.4493 26.39C32.4633 25.305 32.7515 24.2411 33.2872 23.2973C33.8228 22.3536 34.5884 21.5607 35.5129 20.9924C34.9256 20.1536 34.1509 19.4634 33.2502 18.9765C32.3495 18.4896 31.3477 18.2194 30.3244 18.1874C28.1414 17.9582 26.025 19.4937 24.9126 19.4937C23.7787 19.4937 22.0659 18.2101 20.2216 18.2481C19.0286 18.2866 17.866 18.6335 16.8469 19.255C15.8279 19.8765 14.9872 20.7513 14.4068 21.7943C11.8926 26.1472 13.768 32.5446 16.1764 36.0631C17.3813 37.7861 18.7896 39.7107 20.6323 39.6424C22.4354 39.5676 23.1089 38.4926 25.2855 38.4926C27.4418 38.4926 28.0737 39.6424 29.9537 39.599C31.8886 39.5676 33.1077 37.8684 34.2704 36.1292C35.1362 34.9015 35.8024 33.5447 36.2443 32.1089C35.1202 31.6335 34.1609 30.8376 33.4861 29.8207C32.8112 28.8037 32.4506 27.6105 32.4493 26.39Z"
        fill="white"
      />
      <path
        d="M28.8982 15.8737C29.9532 14.6073 30.473 12.9794 30.3471 11.3359C28.7353 11.5052 27.2465 12.2755 26.1773 13.4934C25.6545 14.0884 25.2541 14.7806 24.999 15.5303C24.7439 16.2801 24.639 17.0729 24.6905 17.8632C25.4966 17.8715 26.2942 17.6968 27.023 17.3522C27.7519 17.0076 28.3931 16.5021 28.8982 15.8737Z"
        fill="white"
      />
      <path
        d="M55.2423 35.2819H49.089L47.6112 39.6453H45.0049L50.8333 23.502H53.5412L59.3696 39.6453H56.7188L55.2423 35.2819ZM49.7262 33.2685H54.6038L52.1993 26.187H52.1321L49.7262 33.2685Z"
        fill="white"
      />
      <path
        d="M71.9569 33.7609C71.9569 37.4184 69.9993 39.7683 67.0451 39.7683C66.2968 39.8074 65.5525 39.635 64.8976 39.2708C64.2427 38.9067 63.7035 38.3654 63.3419 37.7091H63.286V43.5387H60.8701V27.8753H63.2086V29.8329H63.2531C63.6313 29.1797 64.1795 28.6413 64.8395 28.2749C65.4994 27.9085 66.2463 27.7278 67.0007 27.7522C69.9878 27.7522 71.9569 30.1135 71.9569 33.7609ZM69.4737 33.7609C69.4737 31.378 68.2423 29.8114 66.3634 29.8114C64.5175 29.8114 63.2759 31.411 63.2759 33.7609C63.2759 36.1323 64.5175 37.7205 66.3634 37.7205C68.2423 37.7205 69.4737 36.1653 69.4737 33.7609Z"
        fill="white"
      />
      <path
        d="M84.911 33.7608C84.911 37.4184 82.9534 39.7682 79.9992 39.7682C79.2509 39.8074 78.5066 39.635 77.8517 39.2708C77.1968 38.9066 76.6576 38.3654 76.296 37.709H76.2401V43.5387H73.8242V27.8753H76.1627V29.8329H76.2071C76.5854 29.1797 77.1336 28.6413 77.7935 28.2749C78.4534 27.9085 79.2003 27.7278 79.9548 27.7522C82.942 27.7522 84.911 30.1135 84.911 33.7608ZM82.4278 33.7608C82.4278 31.378 81.1964 29.8113 79.3175 29.8113C77.4716 29.8113 76.23 31.4109 76.23 33.7608C76.23 36.1323 77.4716 37.7205 79.3175 37.7205C81.1964 37.7205 82.4278 36.1653 82.4278 33.7608H82.4278Z"
        fill="white"
      />
      <path
        d="M93.4721 35.147C93.6511 36.7479 95.2063 37.799 97.3315 37.799C99.3678 37.799 100.833 36.7478 100.833 35.3044C100.833 34.0513 99.9492 33.3011 97.857 32.7869L95.7649 32.2829C92.8005 31.5669 91.4244 30.1805 91.4244 27.9309C91.4244 25.1456 93.8517 23.2324 97.2985 23.2324C100.71 23.2324 103.048 25.1456 103.127 27.9309H100.688C100.542 26.3199 99.2103 25.3475 97.2641 25.3475C95.318 25.3475 93.9862 26.3314 93.9862 27.7634C93.9862 28.9047 94.8368 29.5763 96.9176 30.0904L98.6962 30.5271C102.008 31.3104 103.385 32.6409 103.385 35.0022C103.385 38.0224 100.979 39.914 97.1524 39.914C93.5724 39.914 91.1552 38.0668 90.999 35.1469L93.4721 35.147Z"
        fill="white"
      />
      <path
        d="M108.599 25.0898V27.8752H110.837V29.7884H108.599V36.2769C108.599 37.2849 109.047 37.7546 110.031 37.7546C110.296 37.75 110.562 37.7314 110.825 37.6987V39.6005C110.383 39.6832 109.933 39.7206 109.483 39.7122C107.101 39.7122 106.171 38.8172 106.171 36.5346V29.7884H104.46V27.8752H106.171V25.0898H108.599Z"
        fill="white"
      />
      <path
        d="M112.134 33.7607C112.134 30.0575 114.315 27.7305 117.716 27.7305C121.128 27.7305 123.299 30.0575 123.299 33.7607C123.299 37.4741 121.14 39.791 117.716 39.791C114.293 39.791 112.134 37.4741 112.134 33.7607ZM120.838 33.7607C120.838 31.2204 119.674 29.7211 117.716 29.7211C115.758 29.7211 114.595 31.2318 114.595 33.7607C114.595 36.3112 115.758 37.7991 117.716 37.7991C119.674 37.7991 120.838 36.3112 120.838 33.7607H120.838Z"
        fill="white"
      />
      <path
        d="M125.291 27.8752H127.595V29.8785H127.651C127.807 29.2528 128.174 28.6999 128.689 28.3127C129.205 27.9254 129.838 27.7274 130.482 27.752C130.761 27.7511 131.038 27.7813 131.31 27.8422V30.1019C130.958 29.9945 130.592 29.9452 130.224 29.956C129.873 29.9417 129.524 30.0036 129.199 30.1374C128.874 30.2711 128.582 30.4736 128.343 30.7308C128.104 30.9881 127.923 31.294 127.813 31.6277C127.703 31.9614 127.667 32.3148 127.707 32.6638V39.645H125.291L125.291 27.8752Z"
        fill="white"
      />
      <path
        d="M142.448 36.1881C142.123 38.3247 140.042 39.791 137.38 39.791C133.956 39.791 131.831 37.4969 131.831 33.8166C131.831 30.1248 133.968 27.7305 137.279 27.7305C140.535 27.7305 142.583 29.9674 142.583 33.536V34.3637H134.27V34.5097C134.231 34.9429 134.286 35.3793 134.429 35.7899C134.572 36.2004 134.801 36.5759 135.101 36.8912C135.4 37.2065 135.763 37.4545 136.166 37.6187C136.568 37.7829 137.001 37.8595 137.436 37.8435C138.007 37.897 138.58 37.7648 139.07 37.4665C139.559 37.1682 139.94 36.7199 140.154 36.188L142.448 36.1881ZM134.281 32.6753H140.165C140.187 32.2858 140.128 31.896 139.992 31.5305C139.856 31.1649 139.646 30.8314 139.375 30.5509C139.104 30.2704 138.778 30.049 138.417 29.9005C138.056 29.752 137.669 29.6797 137.279 29.6881C136.885 29.6857 136.495 29.7613 136.131 29.9105C135.767 30.0596 135.436 30.2794 135.158 30.5572C134.879 30.8349 134.658 31.165 134.508 31.5286C134.357 31.8921 134.28 32.2819 134.281 32.6753V32.6753Z"
        fill="white"
      />
      <path
        d="M49.1739 11.3506C49.6804 11.3143 50.1887 11.3908 50.662 11.5746C51.1353 11.7585 51.562 12.0451 51.9111 12.4138C52.2603 12.7825 52.5233 13.2241 52.6811 13.7068C52.8389 14.1894 52.8877 14.7011 52.8238 15.2049C52.8238 17.683 51.4845 19.1075 49.1739 19.1075H46.3721V11.3506H49.1739ZM47.5768 18.0105H49.0393C49.4013 18.0321 49.7635 17.9728 50.0996 17.8368C50.4357 17.7008 50.7372 17.4915 50.9822 17.2243C51.2273 16.9571 51.4097 16.6386 51.5161 16.292C51.6225 15.9454 51.6503 15.5794 51.5974 15.2207C51.6464 14.8634 51.6158 14.4997 51.5078 14.1556C51.3997 13.8116 51.2169 13.4957 50.9724 13.2306C50.7279 12.9655 50.4279 12.7577 50.0936 12.6222C49.7594 12.4867 49.3994 12.4268 49.0393 12.4468H47.5768V18.0105Z"
        fill="white"
      />
      <path
        d="M54.1849 16.1779C54.1481 15.7932 54.1921 15.4051 54.3141 15.0384C54.4361 14.6717 54.6334 14.3346 54.8933 14.0486C55.1533 13.7626 55.4701 13.5341 55.8235 13.3778C56.1769 13.2214 56.5591 13.1406 56.9455 13.1406C57.332 13.1406 57.7142 13.2214 58.0676 13.3778C58.421 13.5341 58.7378 13.7626 58.9977 14.0486C59.2576 14.3346 59.4549 14.6717 59.5769 15.0384C59.6989 15.4051 59.7429 15.7932 59.7061 16.1779C59.7436 16.563 59.7001 16.9517 59.5785 17.319C59.4568 17.6862 59.2596 18.024 58.9996 18.3105C58.7396 18.5971 58.4226 18.826 58.0688 18.9827C57.7151 19.1394 57.3324 19.2204 56.9455 19.2204C56.5586 19.2204 56.176 19.1394 55.8222 18.9827C55.4685 18.826 55.1514 18.5971 54.8914 18.3105C54.6314 18.024 54.4343 17.6862 54.3126 17.319C54.1909 16.9517 54.1474 16.563 54.1849 16.1779ZM58.5178 16.1779C58.5178 14.909 57.9478 14.167 56.9474 14.167C55.9432 14.167 55.3783 14.909 55.3783 16.1779C55.3783 17.457 55.9433 18.1933 56.9474 18.1933C57.9478 18.1933 58.5178 17.4519 58.5178 16.1779H58.5178Z"
        fill="white"
      />
      <path
        d="M67.0451 19.1077H65.8467L64.6368 14.7964H64.5454L63.3406 19.1077H62.1536L60.54 13.2539H61.7118L62.7604 17.7207H62.8468L64.0503 13.2539H65.1586L66.3621 17.7207H66.4535L67.497 13.2539H68.6523L67.0451 19.1077Z"
        fill="white"
      />
      <path
        d="M70.0088 13.2535H71.1209V14.1834H71.2072C71.3537 13.8495 71.6007 13.5695 71.9138 13.3825C72.2269 13.1956 72.5906 13.111 72.9541 13.1405C73.2389 13.1191 73.5249 13.1621 73.7909 13.2662C74.0569 13.3703 74.296 13.5329 74.4906 13.742C74.6852 13.9511 74.8302 14.2013 74.915 14.4741C74.9998 14.7469 75.0221 15.0352 74.9803 15.3178V19.1072H73.825V15.6079C73.825 14.6672 73.4162 14.1993 72.5618 14.1993C72.3684 14.1903 72.1754 14.2232 71.9959 14.2958C71.8164 14.3684 71.6548 14.4789 71.522 14.6198C71.3893 14.7607 71.2885 14.9287 71.2267 15.1121C71.165 15.2956 71.1436 15.4903 71.1641 15.6828V19.1073H70.0088L70.0088 13.2535Z"
        fill="white"
      />
      <path d="M76.8213 10.9688H77.9766V19.1077H76.8213V10.9688Z" fill="white" />
      <path
        d="M79.5833 16.178C79.5466 15.7933 79.5906 15.4051 79.7126 15.0384C79.8346 14.6717 80.032 14.3346 80.2919 14.0486C80.5519 13.7626 80.8687 13.5341 81.2221 13.3778C81.5756 13.2214 81.9578 13.1406 82.3442 13.1406C82.7307 13.1406 83.1129 13.2214 83.4664 13.3778C83.8198 13.5341 84.1366 13.7626 84.3966 14.0486C84.6565 14.3346 84.8538 14.6717 84.9759 15.0384C85.0979 15.4051 85.1419 15.7933 85.1051 16.178C85.1426 16.5631 85.0991 16.9518 84.9774 17.3191C84.8557 17.6864 84.6585 18.0241 84.3984 18.3106C84.1384 18.5972 83.8213 18.8261 83.4676 18.9828C83.1138 19.1395 82.7312 19.2205 82.3442 19.2205C81.9573 19.2205 81.5747 19.1395 81.2209 18.9828C80.8671 18.8261 80.5501 18.5972 80.29 18.3106C80.03 18.0241 79.8328 17.6864 79.7111 17.3191C79.5894 16.9518 79.5459 16.5631 79.5833 16.178ZM83.9162 16.178C83.9162 14.9091 83.3462 14.1671 82.3458 14.1671C81.3416 14.1671 80.7767 14.9091 80.7767 16.178C80.7767 17.4571 81.3417 18.1934 82.3458 18.1934C83.3462 18.1934 83.9162 17.452 83.9162 16.178H83.9162Z"
        fill="white"
      />
      <path
        d="M86.3213 17.4519C86.3213 16.3982 87.1059 15.7908 88.4985 15.7044L90.0842 15.613V15.1078C90.0842 14.4895 89.6754 14.1404 88.8857 14.1404C88.2408 14.1404 87.7939 14.3771 87.6657 14.791H86.5473C86.6653 13.7855 87.6111 13.1406 88.9391 13.1406C90.4066 13.1406 91.2344 13.8712 91.2344 15.1078V19.1074H90.1223V18.2847H90.0308C89.8453 18.5798 89.5848 18.8204 89.2759 18.9819C88.967 19.1433 88.6207 19.2199 88.2726 19.2038C88.0268 19.2294 87.7784 19.2032 87.5434 19.1269C87.3085 19.0506 87.0921 18.9259 86.9082 18.7608C86.7244 18.5957 86.5772 18.394 86.4761 18.1686C86.375 17.9431 86.3223 17.699 86.3213 17.4519ZM90.0842 16.9517V16.4623L88.6547 16.5537C87.8485 16.6077 87.4829 16.8819 87.4829 17.398C87.4829 17.9248 87.9399 18.2314 88.5684 18.2314C88.7525 18.25 88.9385 18.2315 89.1153 18.1767C89.2921 18.122 89.4561 18.0323 89.5976 17.9129C89.739 17.7935 89.8549 17.6469 89.9385 17.4818C90.0221 17.3166 90.0717 17.1364 90.0842 16.9517Z"
        fill="white"
      />
      <path
        d="M92.752 16.1783C92.752 14.3286 93.7028 13.1568 95.1818 13.1568C95.5477 13.1399 95.9108 13.2276 96.2286 13.4094C96.5465 13.5913 96.8061 13.8599 96.9769 14.1838H97.0633V10.9688H98.2185V19.1077H97.1115V18.1828H97.0201C96.836 18.5046 96.5674 18.7698 96.2434 18.9498C95.9194 19.1299 95.5523 19.2178 95.1818 19.2042C93.6927 19.2042 92.752 18.0324 92.752 16.1783ZM93.9453 16.1783C93.9453 17.4199 94.5306 18.167 95.5094 18.167C96.4831 18.167 97.0849 17.4091 97.0849 16.1834C97.0849 14.9633 96.4768 14.1946 95.5094 14.1946C94.5369 14.1946 93.9453 14.9468 93.9453 16.1783H93.9453Z"
        fill="white"
      />
      <path
        d="M102.998 16.1779C102.962 15.7932 103.006 15.4051 103.128 15.0384C103.25 14.6717 103.447 14.3346 103.707 14.0486C103.967 13.7626 104.284 13.5341 104.637 13.3778C104.99 13.2214 105.373 13.1406 105.759 13.1406C106.145 13.1406 106.528 13.2214 106.881 13.3778C107.234 13.5341 107.551 13.7626 107.811 14.0486C108.071 14.3346 108.268 14.6717 108.39 15.0384C108.512 15.4051 108.556 15.7932 108.52 16.1779C108.557 16.563 108.514 16.9517 108.392 17.319C108.27 17.6862 108.073 18.024 107.813 18.3105C107.553 18.5971 107.236 18.826 106.882 18.9827C106.529 19.1394 106.146 19.2204 105.759 19.2204C105.372 19.2204 104.989 19.1394 104.636 18.9827C104.282 18.826 103.965 18.5971 103.705 18.3105C103.445 18.024 103.248 17.6862 103.126 17.319C103.004 16.9517 102.961 16.563 102.998 16.1779ZM107.331 16.1779C107.331 14.909 106.761 14.167 105.761 14.167C104.757 14.167 104.192 14.909 104.192 16.1779C104.192 17.457 104.757 18.1933 105.761 18.1933C106.761 18.1933 107.331 17.4519 107.331 16.1779Z"
        fill="white"
      />
      <path
        d="M110.07 13.2535H111.182V14.1834H111.269C111.415 13.8495 111.662 13.5695 111.975 13.3825C112.288 13.1956 112.652 13.111 113.016 13.1405C113.3 13.1191 113.586 13.1621 113.852 13.2662C114.118 13.3703 114.358 13.5329 114.552 13.742C114.747 13.9511 114.892 14.2013 114.977 14.4741C115.061 14.7469 115.084 15.0352 115.042 15.3178V19.1072H113.887V15.6079C113.887 14.6672 113.478 14.1993 112.623 14.1993C112.43 14.1903 112.237 14.2232 112.057 14.2958C111.878 14.3684 111.716 14.4789 111.584 14.6198C111.451 14.7607 111.35 14.9287 111.288 15.1121C111.226 15.2956 111.205 15.4903 111.226 15.6828V19.1073H110.07V13.2535Z"
        fill="white"
      />
      <path
        d="M121.569 11.7969V13.2809H122.837V14.254H121.569V17.2641C121.569 17.8773 121.822 18.1458 122.397 18.1458C122.544 18.1453 122.691 18.1364 122.837 18.1191V19.0814C122.63 19.1185 122.42 19.1383 122.209 19.1404C120.924 19.1404 120.413 18.6885 120.413 17.5599V14.254H119.483V13.2809H120.413V11.7969H121.569Z"
        fill="white"
      />
      <path
        d="M124.416 10.9688H125.561V14.1946H125.653C125.806 13.8575 126.06 13.5759 126.379 13.3883C126.699 13.2006 127.068 13.116 127.437 13.146C127.721 13.1306 128.004 13.1779 128.267 13.2845C128.53 13.3912 128.766 13.5546 128.959 13.763C129.151 13.9714 129.295 14.2198 129.381 14.4904C129.466 14.761 129.491 15.0471 129.453 15.3283V19.1077H128.297V15.6133C128.297 14.6783 127.862 14.2048 127.045 14.2048C126.847 14.1885 126.647 14.2158 126.46 14.2847C126.273 14.3537 126.104 14.4627 125.963 14.604C125.823 14.7454 125.715 14.9157 125.647 15.1031C125.58 15.2905 125.554 15.4904 125.571 15.6888V19.1077H124.416L124.416 10.9688Z"
        fill="white"
      />
      <path
        d="M136.189 17.5269C136.032 18.0619 135.692 18.5247 135.229 18.8343C134.765 19.1439 134.207 19.2806 133.653 19.2205C133.267 19.2307 132.884 19.1568 132.53 19.004C132.175 18.8512 131.859 18.6232 131.601 18.3357C131.344 18.0482 131.153 17.7082 131.04 17.3392C130.927 16.9703 130.896 16.5812 130.949 16.199C130.897 15.8157 130.929 15.4258 131.041 15.0557C131.154 14.6856 131.345 14.344 131.6 14.054C131.856 13.764 132.172 13.5324 132.525 13.3748C132.878 13.2173 133.261 13.1374 133.648 13.1407C135.277 13.1407 136.259 14.2535 136.259 16.0917V16.4948H132.126V16.5596C132.108 16.7744 132.135 16.9906 132.205 17.1944C132.275 17.3982 132.387 17.585 132.534 17.7429C132.681 17.9008 132.859 18.0262 133.057 18.1113C133.255 18.1963 133.469 18.239 133.685 18.2366C133.961 18.2697 134.241 18.22 134.489 18.0936C134.737 17.9672 134.942 17.77 135.077 17.5269L136.189 17.5269ZM132.126 15.6404H135.082C135.097 15.444 135.07 15.2466 135.004 15.0611C134.938 14.8755 134.833 14.7059 134.698 14.5631C134.562 14.4203 134.398 14.3075 134.216 14.2318C134.034 14.1562 133.838 14.1195 133.641 14.124C133.442 14.1215 133.243 14.159 133.058 14.2343C132.873 14.3096 132.705 14.4213 132.564 14.5626C132.423 14.7039 132.311 14.8721 132.236 15.0572C132.16 15.2423 132.123 15.4406 132.126 15.6404H132.126Z"
        fill="white"
      />
    </svg>
  );
};
