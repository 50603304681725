import React from 'react';

export const Wifi = (props) => {
  return (
    <svg width="102" height="80" viewBox="0 0 102 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M54.4132 0.122233C55.6559 0.177368 57.317 0.290466 59.2601 0.537871C63.3564 1.07651 67.3883 2.02582 71.2937 3.37237C76.8391 5.29141 82.0939 7.96558 86.9097 11.3189C92.0351 14.875 96.6275 19.1438 100.549 23.9958C102.035 25.8223 101.966 27.3702 100.341 29.0604C99.2733 30.1715 98.206 31.282 97.1387 32.3932C96.6014 32.9827 95.8288 33.3008 95.0329 33.2598C94.237 33.2188 93.5011 32.823 93.0275 32.1819C86.9966 24.5005 79.5371 18.6795 70.4641 15.0341C48.7295 6.31564 24.1231 12.9672 9.4059 31.4948C7.29378 34.1547 6.48237 34.1921 4.12144 31.7464C3.19121 30.7823 2.25324 29.8245 1.33634 28.8489C0.75035 28.2212 0.412468 27.402 0.38632 26.5439C0.360872 25.6851 0.648562 24.8475 1.19639 24.1859C4.50372 20.1638 11.5554 12.172 22.0624 7.02057C22.0624 7.02057 31.2382 2.52218 40.5399 0.865166C42.0101 0.60363 43.4592 0.419851 43.4592 0.419851C44.1562 0.330786 45.3352 0.184464 46.8889 0.0890455C47.9202 0.0254285 49.1791 -0.0155691 50.6155 0.00563532L54.4132 0.122233Z"
        fill="#3C7BFF"
      />
      <path
        d="M53.47 79.5721C52.7914 79.8117 50.897 80.3758 48.8245 79.6138C46.0712 78.6001 44.0744 75.6278 44.3855 72.3875C44.688 69.2207 47.0694 66.6477 50.1945 66.2617C52.9137 65.9231 55.0677 67.0138 56.5039 69.2976C57.268 70.4314 57.6737 71.7688 57.6681 73.1359C57.6617 74.503 57.2447 75.8361 56.4707 76.9635C55.8253 77.951 54.9559 78.7716 53.9331 79.3597L53.47 79.5721Z"
        fill="#3C7BFF"
      />
      <path
        d="M51.0468 22.4638C65.4455 22.842 76.8657 28.9393 85.4973 40.4032C86.554 41.8085 86.402 43.5897 85.1749 44.9004C83.9478 46.2109 82.725 47.4734 81.4838 48.7401C80.1203 50.1375 78.8592 50.0407 77.7749 48.4135C74.8259 43.992 71.0832 40.4154 66.4496 37.8028C52.0155 29.6661 34.006 34.0423 24.513 47.9604L24.5123 47.9597C24.3024 48.3046 24.0705 48.6347 23.8182 48.9493C22.9869 49.8852 21.7775 49.994 20.8932 49.1218C19.4385 47.689 18.0135 46.2201 16.6456 44.7039C15.5351 43.4711 15.5118 41.7252 16.5325 40.3497C23.4753 31.0573 32.5401 25.1317 44.0306 23.0811C44.8541 22.9348 45.6811 22.7998 46.5124 22.7171C48.0173 22.5368 49.5315 22.452 51.047 22.464L51.0468 22.4638Z"
        fill="#3C7BFF"
      />
      <path
        d="M51.1884 44.9531C59.3803 45.1511 66.183 48.4818 71.5351 54.8138C72.6788 56.1674 72.6018 57.9389 71.3591 59.2747C70.0825 60.6481 68.772 61.9905 67.4559 63.3265C66.3023 64.4985 65.0631 64.4306 64.0518 63.1144C63.1979 61.9524 62.216 60.8899 61.1246 59.9477C54.1974 54.2179 44.3465 55.1976 38.677 62.1785C38.424 62.4896 38.1921 62.8175 37.939 63.1307C36.9021 64.4094 35.6849 64.4808 34.5271 63.3032C33.2364 61.9834 31.9592 60.6574 30.693 59.325C29.3472 57.8922 29.3231 56.1364 30.6117 54.6386C35.9937 48.3837 42.7903 45.1158 51.1901 44.9533L51.1884 44.9531Z"
        fill="#3C7BFF"
      />
    </svg>
  );
};
