import { Client } from '../clients';

export class NotificationClient {
  async sendNotificationDelivery(email) {
    try {
      const response = await Client.post('/v2/delivery/notify', { user: email });
      return response;
    } catch (error) {
      throw error;
    }
  }
  async sendNotificationAboutGuest(data) {
    try {
      const response = await Client.post(`/v2/users/${data.user}/visitor/notify`, { visitor: data.visitor });
      return response;
    } catch (error) {
      throw error;
    }
  }
}
