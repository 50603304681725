import React from 'react';
import './WelcomePage.scss';
import { Trans } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { AppStore, ArrowRight, GooglePlay } from '../../icons';
import { PATH_APP } from '../../routes';

const WelcomePage = ({ welcomeText }) => {
  return (
    <div className="welcome">
      <div>
        <div className="welcome_title">{welcomeText}</div>
      </div>
      <div className="row welcome_buttons">
        <div className="item buttons">
          <NavLink to="/visitor" className="buttons_item">
            <Trans i18nKey="welcome.guest_btn" />
            <ArrowRight />
          </NavLink>
          <NavLink to="/delivery" className="buttons_item">
            <Trans i18nKey="delivery.title" />
            <ArrowRight />
          </NavLink>
        </div>
        <div className="item apps">
          <div className="apps_wrapper">
            <div className="apps_title">
              <Trans i18nKey="welcome.apps" />
            </div>
            <div className="row">
              <div className="item qr">
                <img src={`${process.env.PUBLIC_URL}/appsQr.png`} />
              </div>
              <div className="item icons">
                <a href="https://apps.apple.com/us/app/inspace-app/id1569382208" className="icons_link">
                  <AppStore />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=app.inspace&hl=en_US&gl=US"
                  className="icons_link"
                >
                  <GooglePlay />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;
