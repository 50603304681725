import React, { useState } from 'react';
import './SearchUsers.scss';

const SearchUsers = ({
  users,
  value = '',
  onBlur,
  onChange,
  setValueUser,
  helperText,
  error,
  placeholder,
  typeSearch,
  hostsList,
}) => {
  const [filterUsers, setFilterUsers] = useState(hostsList);
  const filteredUsers = (e) => {
    const value = e.target.value.toLowerCase().trim();
    let result = [];
    if (value === '') {
      setFilterUsers([]);
      setValueUser('');
    } else if (typeSearch === 'phone') {
      result = users.filter((user) => user.phone && user.phone.indexOf(value) !== -1).map((item) => item);
    } else {
      result = users
        .filter(
          (user) =>
            (user.firstName && user.firstName.toLowerCase().indexOf(value) !== -1) ||
            (user.lastName && user.lastName.toLowerCase().indexOf(value) !== -1),
        )
        .map((item) => item);
    }
    setFilterUsers(result);
    setValueUser(e.target.value);
  };

  const handleSelectUser = (user) => {
    setValueUser(`${user.firstName} ${user.lastName}`);
    onChange(user._id);
  };

  return (
    <div className="form_field">
      <input
        className={`form_input ${error ? 'error' : ''}`}
        name="hosts"
        placeholder={placeholder}
        value={value}
        onChange={filteredUsers}
        onBlur={onBlur}
      />
      {error && <div className="error_text">{helperText}</div>}
      <div className="form_users">
        {filterUsers.map((user) => (
          <div key={user._id} className="user" onClick={(e) => handleSelectUser(user)}>
            <div className="user_avatar">
              <div className="avatar">
                {`${user.firstName[0]}${user.lastName ? user.lastName[0] : ''}`.toUpperCase()}
              </div>
            </div>
            <div className="user_name">{`${user.firstName} ${user.lastName}`}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SearchUsers;
