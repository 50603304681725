import React from 'react';
import { Trans } from 'react-i18next';
import CardLayout from './CardLayout';
import './ThanksCard.scss';
import Spinner from './Spinner';

const ThanksCard = ({ user, onClick, text, error, onBack, loading }) => {
  return (
    <CardLayout title="" buttonText={'next_btn'} onBack={error ? onBack : null} onClick={error ? null : onClick}>
      {loading ? (
        <Spinner />
      ) : (
        <div className="thanks">
          {error ? (
            <div className="thanks_error">
              <div className="thanks_error-title">
                <Trans i18nKey="error_title" />
              </div>
              <div className="thanks_error-text">{error}</div>
            </div>
          ) : (
            <>
              <div className="thanks_title">
                <Trans i18nKey="thanks" />
              </div>
              <div className="thanks_smiles">
                <img src={`${process.env.PUBLIC_URL}/smile-sunglasses.png`} />
                <img src={`${process.env.PUBLIC_URL}/smile-cool.png`} />
              </div>
              <div className="thanks_text">
                <Trans
                  i18nKey={text}
                  values={{
                    name: user,
                  }}
                />
              </div>
            </>
          )}
        </div>
      )}
    </CardLayout>
  );
};

export default ThanksCard;
