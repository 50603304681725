import React, { useContext, useEffect, useMemo, useState } from 'react';
import './MainLayout.scss';
import { Fullscreen } from '../../icons';
import { Trans } from 'react-i18next';
import { DeviceAuthContext } from '@inspace-org/react-auth';
import { useFullscreen } from '@inspace-org/react-fullscreen';
import { Client } from '../../services/http';

const MainLayout = ({ children }) => {
  const { branding, settings } = useContext(DeviceAuthContext);
  const { toggleFullscreen } = useFullscreen();
  const [bgImage, setBgImage] = useState(`${process.env.PUBLIC_URL}/background.png`);

  useEffect(() => {
    if (settings?.tablet?.bgImage) {
      setBgImage(settings?.tablet?.bgImage);
    }
  }, [settings]);

  return (
    <div
      className="container"
      style={{
        backgroundImage: `url('${bgImage}')`,
      }}
    >
      <div className="wrapper">
        <div className="header">
          <div className="header_logo">
            <img
              src={`${
                branding?.isEnabled && branding?.fullLogo
                  ? branding?.fullLogo
                  : `${process.env.PUBLIC_URL}/logo-white.png`
              }`}
            />
          </div>
        </div>
        <div className="content">{children}</div>
        <div className="footer">
          <div className="fullscreen">
            <button type="button" className="fullscreen_button" onClick={toggleFullscreen}>
              <p className="fullscreen_text">
                <Trans i18nKey="fullscreen_btn" />
              </p>
              <Fullscreen className="fullscreen_img" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
