import {
  DEFAULT_LS_ACCESS_TOKEN_KEY,
  DEFAULT_LS_EXCHANGE_TOKEN_KEY,
  DEFAULT_LS_REFRESH_TOKEN_KEY,
  LocalApiCredentials,
} from '@inspace-org/client';

export const apiCredentials = new LocalApiCredentials({
  exchangeTokenKey: `RECEPTION_TABLET${DEFAULT_LS_EXCHANGE_TOKEN_KEY}`,
  accessTokenKey: `RECEPTION_TABLET${DEFAULT_LS_ACCESS_TOKEN_KEY}`,
  refreshTokenKey: `RECEPTION_TABLET${DEFAULT_LS_REFRESH_TOKEN_KEY}`,
});
