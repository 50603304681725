import { ApiClientV2, Client } from '../clients';

export class UsersClient {
  async getUsers(params) {
    const newParams = {
      all: true,
      withMe: true,
      ...params,
    };
    try {
      const response = await Client.get('/v1/users', newParams);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async createUser(params) {
    try {
      const response = await Client.post('/v1/admin/users', params);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async updateUser(userId, params) {
    try {
      const response = await Client.put(`/v1/admin/users/${userId}`, params);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async uploadAvatarUser(file, email) {
    const formData = new FormData();
    formData.append('avatar', file);
    try {
      const response = await Client.post(`/v1/admin/users/${email}/avatar-upload`, formData, 'multipart/form-data');
      return response;
    } catch (error) {
      throw error;
    }
  }
}
