import React, { useContext, useEffect } from 'react';
import { DeviceAuthContext } from '@inspace-org/react-auth';
import { useNavigate } from 'react-router-dom';
import { PATH_APP } from '../../routes';

const MainPage = () => {
  const navigate = useNavigate();

  const { isInitialized, isAuthenticated, device } = useContext(DeviceAuthContext);

  useEffect(() => {
    if (isInitialized && isAuthenticated) {
      navigate(PATH_APP.welcome, { replace: true });
    }
  }, [isInitialized, isAuthenticated, device, navigate]);

  return (
    <section>
      <p>
        Please, open this page from your settings at
        <br />
        <a href="https://admin.inspace.app">https://admin.inspace.app</a>
      </p>
    </section>
  );
};

export default MainPage;
